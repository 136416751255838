import React, { useContext } from 'react';
import UserContext from '../contexts/UserContext';
import classes from './ReferralProgram.module.css';

const ReferralProgram = () => {
  const { toggleModal } = useContext(UserContext);

  return (
    <div className={classes['get-it-free-section']} id="get-it-free">
      <h2>Exciting opportunity alert!</h2>
      <p className={classes['justified-text']}>Earn ₱500.00 for each new member you bring into our community. Plus, get a bonus of ₱2,000.00 when you introduce four friends. You can use your rewards for layaway purchases or even make one-time payments!</p>
      <div className={classes['referral-info-graphic']}>
        {/* This div would ideally contain an SVG or image that graphically represents the referral rewards and how they can be applied as payments */}
      </div>
      <ul className={classes['referral-steps']}>
        <li>Sign up and receive your unique referral link.</li>
        <li>Share your link with friends and family.</li>
        <li>Track your progress and see your accumulated bonuses.</li>
        <li>Apply your bonuses as payments towards layaways or full purchases.</li>
      </ul>
      <div className={classes['testimonials']}>
        {/* Map through testimonials specific to the referral program */}
      </div>
      <button className={classes['cta-button']} onClick={toggleModal}>Earn & Spend Your Bonuses Now!</button>
    </div>
  );
};

export default ReferralProgram;
