import React, {useState} from 'react';
import PublicHeader from '../components/layout/Header/PublicHeader';
import PublicFooter from '../components/layout/Footer/PublicFooter';
import { retrieveFromLocalStorage, saveToLocalStorage } from '../helpers/localStorageHelper';
import { postData } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classes from './Salespage.module.css';
import { FaHandPointDown } from 'react-icons/fa';


const Salespage = () => {
  const videoId = 'O8WGdbwldrQ'; 
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = retrieveFromLocalStorage("userDetails");

  const url = new URL(window.location.href); //the link is coming from email
  const searchParams = new URLSearchParams(url.search);
  const tid = searchParams.get('tid');

  const showWarning = (message) => {
    toast.warn(message);
  };

  const handlePayment= async () => {
    let response_tid = userDetails?.transaction_id ? userDetails.transaction_id : tid;

    if(!response_tid){
      showWarning('Invalid Referral Code');
      return;
    }

    setIsLoading(true);

    const data = {
      'response_tid': response_tid,
      'endpoint_type': 'public'
    };

    try {
      const response = await postData('payment/signup', data);
      if(response?.data){
        const checkoutUrl = response.data.attributes.checkout_url;
        // const paymentId = response.data.id;
        
        data['paymentId'] = response.data.id; 

        saveToLocalStorage("paymentDetails", data);
        // localStorage.setItem('paymentId', paymentId);
  
        // Redirect to the checkout URL
        window.location.href = checkoutUrl;
      }
      


    } catch (error) {
      console.error("error on submit:", error);
    } finally {
      setIsLoading(false); 
    }    
  };

  return (
        <div className={classes.container}>
          <PublicHeader />        
          <div className={classes['video-section']}>
            <div className={classes['text-container']}>
                <div className={classes['big-text']}>PAANO BA MAKA</div>
                <div className={classes['big-text']}>TRAVEL FOR FREE?</div>
                <div className={classes['mechanics']}>PANOORIN ANG FULL MECHANICS BELOW</div>
            </div>
            <FaHandPointDown className={classes['blinking-icon']} />
            <div className={classes['iframe-container']}>
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>  

          <div className={classes['member-now-section']}>
            <div className={classes['text-container']}>
                <div className={classes['member-now-text']}>BE A MEMBER NOW!</div>
                <div className={classes['lock-in-text']}>LOCK IN YOUR SPOT & REGISTER NOW to ENSURE YOU ARE </div>
                <div className={classes['lock-in-text']}>ON TOP OF THIS TIME-SENSITIVE OPPORTUNITY!</div>
            </div>

            <button className={`${classes.button} ${classes.submitButton}`} disabled={isLoading} onClick={handlePayment}>
              {isLoading ? (
                      <>
                          <div className={classes.spinner}></div>
                          <span className={classes.loadingText}>Processing...</span>
                      </>
                  ) : 'LOCK YOUR SPOT HERE'}              
              </button>

            <div className={classes['text-container']}>
                <div className={classes['lock-in-text-italic']}> (please be sure to open your email including SPAM Messages)</div>
            </div>
          </div>

          <PublicFooter />        
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
        </div>
  );
};

export default Salespage;
