import React, { useState, useEffect,useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicHeader from '../components/layout/Header/PublicHeader';
import PublicFooter from '../components/layout/Footer/PublicFooter';
import ReferralProgram from './ReferralProgram';
import { postData } from '../services/api';
import { saveToLocalStorage } from '../helpers/localStorageHelper';
import classes from './Homepage.module.css';

import founder1 from '../assets/image/founder1.jpg'; 
import founder2 from '../assets/image/founder2.jpg';
import product4 from '../assets/image/products/4.jpg';
import product5 from '../assets/image/products/5.png';
import product6 from '../assets/image/products/6.png';
import product7 from '../assets/image/products/7.png';
import eco from '../assets/image/benefits-icons/eco.png';
import cost_effective from '../assets/image/benefits-icons/cost-effective.png';
import portable from '../assets/image/benefits-icons/portable.png';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TestimonialModal = ({ testimonial, onClose }) => {
  if (!testimonial) return null;

  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
        <img 
          src={testimonial.image} 
          alt={testimonial.name}
          className={classes.modalImage} />
        <h3>{testimonial.name}</h3>
        <h4>{testimonial.title}</h4>
        <h5>{testimonial.position}</h5>
        <p>{testimonial.quote}</p>
        <p>{testimonial.quote1}</p>
        <p>{testimonial.quote2}</p>
        <p>{testimonial.quote3}</p>

        <button onClick={onClose} className={classes.closeButton}>Close</button>
      </div>
    </div>
  );
};


const testimonials = [
  {
    name: "JOHN GARING",
    quote: "John, with over a decade of experience in the IT industry, has a proven track record of developing and deploying multiple web applications.",
    quote1: "As a systems consultant, he has worked with an array of clients both locally and internationally.",    
    quote2: "John's passion for technology is at the core of his work, driving him to continuously explore, research, and innovate in the field.",    
    quote3: "As the founder of 428AM Tech. Solution, John is dedicated to helping small and medium businesses automate their operations, enhancing efficiency and driving growth.",    
    title: "The Founder",
    position: "Founder & Chief Technology Officer",
    image: founder2
  },
  {
    name: "MARLON GARING",
    quote: "Marlon, our esteemed Engineering Lead, brings a wealth of expertise and experience to our team.",
    quote1: "With a background in both software and hardware engineering, Marlon has spent over seven years in the tech industry, developing innovative solutions and leading complex projects.",    
    quote2: "He holds a degree in Computer Engineering from Misamis University and has worked with several high-profile tech companies, where he gained a reputation for his problem-solving skills and forward-thinking approach.",    
    quote3: "At our company, Marlon oversees the engineering team, guiding them through the intricacies of product development and ensuring that all projects meet our high standards of quality and innovation. His leadership is not just about managing tasks; it's about inspiring his team to think creatively and push the boundaries of what's possible. Marlon’s dedication to mentoring junior engineers and fostering a collaborative work environment has been instrumental in our company's success.",    
    title: "Engineering Lead",
    position: "Engineering Lead",
    image: founder1
  }
 

];

const Homepage = () => {
  const [current, setCurrent] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');  
  const [shakeName, setShakeName] = useState(false);
  const [shakeEmail, setShakeEmail] = useState(false);
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [expanded, setExpanded] = useState('panel2');

  const videoId = 'oSTu2B9LKo4'; 

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsModalOpen(true);
  };

  const showWarning = (message) => {
    toast.warn(message);
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    if (shakeName) setShakeName(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (shakeEmail) setShakeEmail(false);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    let valid = true;
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!code) {
      showWarning('Invalid Referral Link!');
      return;
    }

    if (!name) {
      setShakeName(true);
      nameInputRef.current.focus();
      valid = false;
    }

    if (valid && (!email || !validateEmail(email))) {
      setShakeEmail(true);
      emailInputRef.current.focus();
      valid = false;
    }

    if (valid) {
      setIsLoading(true); // Start loading

      const data = {
        'code': code,
        'endpoint_type': 'public',
        'name': name,
        'email': email
      };

      try {
        const response = await postData('sales/landing', data);
        
        if(response.code === 200){
          data['transaction_id'] = response.data.TransactionID; 
          saveToLocalStorage("userDetails", data);
          navigate('/offer');        
        }

      } catch (error) {
        console.error("error on submit:", error);
      } finally {
        setIsLoading(false); 
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((current) => (current + 1) % testimonials.length); 
    }, 1000); 
    return () => clearInterval(interval); 
  }, []);

  return (
        <div className={classes.container}>
          <PublicHeader />        
          <div className={classes['top-section']}>
            <h1>Portable Power, Take It To Every Corner Of The World!</h1>
            <p className={classes['description']}>
            Unlock the sun's power with our Portable Solar Generator! Simple, eco-friendly, and efficient energy for your life. Go beyond just powering up—power smart and sustainably. Join the solar revolution today!
            </p>
          </div>
          <div className={classes['video-section']}>
            <div className={classes['iframe-container']}>
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>  

          <div className={classes['product-highlight']} id="product-highlight">
            <div className={classes['product-info']}>
              {/* Charging Methods Accordion */}
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4 className={classes.AccordionSummaryLabel}>Charging Methods</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>Solar Charging</li>
                    <li>AC Charging</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              {/* Specifications Accordion */}
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4 className={classes.AccordionSummaryLabel}>Specifications</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>Constant Power: 600w Pure Sine Wave</li>
                    <li>Peak Power: 1200w</li>
                    <li>Solar Charging: MPPT</li>
                    <li>PV input: 20v-50v, 20a max</li>
                    <li>Max PV Input: 300w</li>
                    <li>AC Charging: 14.6v 10a</li>
                    <li>Battery Life Cycle: 8 to 12 years</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              {/* Safety Features Accordion */}
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <h4 className={classes.AccordionSummaryLabel}>Safety Features</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>Automatic Low Voltage Disconnect</li>
                    <li>Over Charged Protection</li>
                    <li>Over Discharge Protection</li>
                    <li>Over Current Protection</li>
                    <li>Short Circuit Protection</li>
                    <li>Temperature Protection</li>
                    <li>Battery Voltage Monitoring</li>
                    <li>Active Balancing of higher currents</li>
                    <li>Auto Heat Cooling</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className={classes['product-image']}>
              <img src={product4} alt="Portable Solar Generator" />
            </div>
          </div>

          <div className={classes['benefits-section']} id="benefits-section">
            <h2>Why Choose Our Portable Solar Generator?</h2>
            <div className={classes['benefit']}>
              <img src={eco} alt="Eco-Friendly" className={classes['benefit-icon']} />
              <h3>Eco-Friendly</h3>
              <p>Solar power is clean energy. It doesn't pollute the air or water because it doesn't burn anything like coal or gas. This means you're helping to keep the environment clean.</p>
            </div>
            <div className={classes['benefit']}>
              <img src={cost_effective} alt="Cost-Effective" className={classes['benefit-icon']} />
              <h3>Cost-Effective</h3>
              <p>Once you set up solar panels, you basically get your electricity for free from the sun.
                 Over time, this saves you a lot of money because you're not paying the electric company as much.</p>
            </div>
            <div className={classes['benefit']}>
              <img src={portable} alt="Portable" className={classes['benefit-icon']} />
              <h3>Portable</h3>
              <p>Ultra-compact, super light, and hassle-free! Ideal for all your adventures - from camping to any outdoor fun. Bring energy wherever you go, effortlessly.</p>
            </div>

          </div>

          <div className={classes['get-it-free-section']} id="get-it-free">
            <ReferralProgram />
          </div>

          <div className={classes['features-section']} id="features-section">
            <h2>Discover What Sets Our Solar Generator Apart!</h2>
            <div className={classes.feature}>
              <img src={product5} alt="Sun Power" className={classes['feature-icon']} />
              <h3>Unleash Sun Power</h3>
              <p>Supercharge your adventures with our solar panels! They soak up more sun to power your world efficiently.</p>
            </div>
            <div className={classes.feature}>
              <img src={product6} alt="Easy Control" className={classes['feature-icon']} />
              <h3>Effortless Control</h3>
              <p>Take charge with ease! Our user-friendly setup lets you manage your energy effortlessly. Powering up, simplified.</p>
            </div>
          </div>


          <div className={classes['comment-section']}>       
            <div className={classes.testimonial}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className={classes.testimonialItem}>
                  <img src={testimonial.image} alt={testimonial.name} className={classes.testimonialImage} />
                  <div>
                    <h3 className={classes.testimonialName}>{testimonial.name}</h3>
                    <h4 className={classes.testimonialTitle}>{testimonial.title}</h4>
                    <h5 className={classes.testimonialPosition}>{testimonial.position}</h5>
                    <p className={classes.testimonialQuote}>
                      "{testimonial.quote}" 
                      <a href="#!" onClick={() => openModal(testimonial)} className={classes.moreInfoLink}>More Info</a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <PublicFooter />      
          <ToastContainer position={toast.POSITION.TOP_CENTER} />

          {isModalOpen && 
            <TestimonialModal 
              testimonial={selectedTestimonial} 
              onClose={() => setIsModalOpen(false)} 
            />
          }          
        </div>
  );
};

export default Homepage;
