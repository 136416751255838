import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f2f2f2'
    },
    errorCode: {
      fontSize: '8rem',
      fontWeight: 'bold',
      color: '#555',
    },
    message: {
      fontSize: '2rem',
      marginBottom: '1.5rem',
      color: '#333',
    },
    homeLink: {
      fontSize: '1.2rem',
      padding: '10px 20px',
      border: '2px solid #007bff',
      borderRadius: '5px',
      color: '#007bff',
      textDecoration: 'none'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.errorCode}>404</div>
      <div style={styles.message}>Page Not Found</div>
      <Link to="/" style={styles.homeLink}>Go to Home</Link>
    </div>
  );
};

export default NotFound;
