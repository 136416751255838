import React, { useEffect, useState, useRef  } from 'react';
import { postData } from '../services/api';
import { LOGIN_PAGE } from '../utils/constants';
import classes from './PaymentSuccess.module.css';

import CompanyLogo from '../assets/image/logo.png';
import LoadingIcon from '../assets/image/Skateboarding.gif';

const AccountActivation = () => {
    const isDataFetched = useRef(false);
    const [customer, setCustomer] = useState('');   
    const [isLoading, setIsLoading] = useState(true);
    const [activationStatus, setActivationStatus] = useState(null); 
    const [countdown, setCountdown] = useState(10);
    const [referralCode, setReferralCode] = useState(null); 

    const url = new URL(window.location.href); //the link is coming from email
    const searchParams = new URLSearchParams(url.search);
    const mt = searchParams.get('mt');
    const tid = searchParams.get('tid');

    const userData = {
        'member_token': mt,
        'response_tid': tid,
        'endpoint_type': 'public'
    };

    const fetchData = async () => {
        setIsLoading(true); 

        try {
          const response = await postData('customer/activate', userData);

          setActivationStatus(response?.code);
          setCustomer(response?.data?.customer);
          setReferralCode(response?.data?.refererral_code)
        } catch (error) {
          setActivationStatus(500);
        } finally {
          setIsLoading(false); 
        }
    };

    useEffect(() => {
        if (!isDataFetched.current) {
            fetchData();
            isDataFetched.current = true;
        }
    }, []);

    useEffect(() => {
        if (activationStatus === 200 || activationStatus === 201) {
          const interval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
          }, 1000);
    
          const timer = setTimeout(() => {
              window.location.href = LOGIN_PAGE + '/?code=' + encodeURIComponent(referralCode);
          }, 10000);
    
          return () => {
            clearInterval(interval);
            clearTimeout(timer);
          };
        }
    }, [activationStatus]);

    return (
        <>
            {isLoading ? (
                <>
                    <img src={LoadingIcon} alt="Loading" className={classes.loadingIcon} />
                    <h1 className={classes.description}>Processing...</h1>
                </>
            ) : (
                <div className={classes.container}>
                    <div className={classes.content}>
                        <img src={CompanyLogo} alt="Company Logo" className={classes.logo} />
                        {activationStatus === 200 ? (
                            <>
                                <h1 className={classes.title}>Account Activated!</h1>
                                <p className={classes.description}>Hi, <span style={{ textTransform: 'capitalize' }}>{customer}</span></p>
                                <p className={classes.description}>Your account has been successfully activated.</p>
                                <p className={classes.description}>Please check your email for login details and temporary password. Make sure to reset your password once you log in.</p>
                                <p className={classes.countdown}>Redirecting in {countdown} seconds...</p>
                            </>
                        ) : activationStatus === 201 ? (
                            <>
                                <h1 className={classes.title}>Account Already Activated!</h1>
                                <p className={classes.description}>Your account is already activated.</p>
                                <p className={classes.countdown}>Redirecting in {countdown} seconds...</p>
                            </>
                        ) : (
                            <>
                                <h1 className={classes['error-title']}>Activation Error</h1>
                                <p className={classes['error-description']}>Oops! Unexpected error occurred. Our apologies.</p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
    
};

export default AccountActivation;
