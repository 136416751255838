import React from 'react';
import classes from './PublicFooter.module.css';
import Logo from '../../../assets/image/logo.png';

import FacebookIcon from '../../../assets/icons/facebook.png';
import InstagramIcon from '../../../assets/icons/instagram.png';
import YouTubeIcon from '../../../assets/icons/youtube.png';
import TikTokIcon from '../../../assets/icons/tiktok.png';

const PublicFooter = () => {
    return (
        <div className={classes['footer-section']}>       
            <img src={Logo} alt='Logo' className={classes['logo-footer']} />
            <div className={classes['social-icons']}>
                <a href="https://www.facebook.com/428am.xyz" target="_blank" rel="noopener noreferrer">
                    <img src={FacebookIcon} alt='Facebook' className={classes['social-icon']} />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <img src={InstagramIcon} alt='Instagram' className={classes['social-icon']} />
                </a>
                <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                    <img src={YouTubeIcon} alt='YouTube' className={classes['social-icon']} />
                </a>
                <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                    <img src={TikTokIcon} alt='TikTok' className={classes['social-icon']} />
                </a>
            </div>     
        </div>
    );
};

export default PublicFooter;
