import React, { useContext, useState, useEffect, useRef  } from 'react';
import classes from './PublicHeader.module.css';
import Logo from '../../../assets/image/logo/navbar.png';
import { postData, fetchData } from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import { LAY_AWAY_CODE, 
  ONE_TIME_PAYMENT_CODE, 
  DEVICE_ONLY_CODE, 
  DEVICE_WITH_PANEL_CODE,
  DISTRIBUTOR_DEVICE_ONLY_CODE,
  DISTRIBUTOR_DEVICE_WITH_PANEL_CODE,
  DEVICE_WITH_PANEL_OTP_CODE,
  ONE_TIME_DEVICE_ONLY_CODE
 } from '../../../utils/constants';
import { saveToLocalStorage } from '../../../helpers/localStorageHelper';
import { formatCurrency } from '../../../utils/currencyUtils';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from '../../../contexts/UserContext';

import product1 from '../../../assets/image/products/1.jpg'; 
import product2 from '../../../assets/image/products/2.jpg'; 
import product3 from '../../../assets/image/products/3.jpg'; 

const PublicHeader = () => {
  const { isModalOpen, toggleModal } = useContext(UserContext);

  const [isFloating, setIsFloating] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOrderContent, setShowOrderContent] = useState(false);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [selectedOption, setSelectedOption] = useState(DEVICE_ONLY_CODE);
  const [isOneTimePayment, setIsOneTimePayment] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [orderType, setOrderType] = useState(LAY_AWAY_CODE);
  const [packages, setPackages] = useState([]);
  const [priceDetails, setPriceDetails] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [selectedPackageID, setSelectedPackageID] = useState('');
  const [selectedPackageCode, setSelectedPackageCode] = useState('');
  const [selectedPackageTerm, setSelectedPackageTerm] = useState('');
  const [selectedOrderTypeCode, setSelectedOrderTypeCode] = useState('');
  const [minimumDistributorOrder, setMinimumDistributorOrder] = useState(0);
  const [isDistributor, setIsDistributor] = useState(false);
  const [totalCost, setTotalCost] = useState(0.00);
  const [distributorPackage, setDistributorPackage] = useState({});
  const [nonDistributorPackage, setNonDistributorPackage] = useState({});
  const [savingsToday, setSavingsToday] = useState('');
  
  const showWarning = (message) => {
    toast.warn(message);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Set the header to float when the user scrolls down
      setIsFloating(window.scrollY > 0);
    };

    // Add the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleToggleModal = () => {
    if (!isModalOpen) {
      setIsOneTimePayment(false);
      setOrderType(LAY_AWAY_CODE);
      setQuantity(1);
    } else {
      setShowOrderContent(false);
    }
    toggleModal();  // Call context's toggleModal
  };

  // const toggleModal = () => {
  //   if (!isModalOpen) {
  //     setIsOneTimePayment(false);
  //     setOrderType(LAY_AWAY_CODE);
  //     setQuantity(1);
  //   }else{
  //     setShowOrderContent(false);
  //   }
  //   setIsModalOpen(!isModalOpen);
  // };

  // Smooth scroll function
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 90,
        behavior: 'smooth',
      });
    }
  };

  const handleOrderTypeChange = async (event) => {
    const selectedOrderType = event.target.value;
    setIsOneTimePayment(selectedOrderType === ONE_TIME_PAYMENT_CODE);
    setOrderType(selectedOrderType);    
    setSelectedOrderTypeCode(selectedOrderType);    

    if(selectedOrderType == ONE_TIME_PAYMENT_CODE){
      console.log(selectedOrderType)
      console.log(selectedPackageID)

      try {
        let code = '';
        if(selectedPackageCode == DEVICE_ONLY_CODE){
          code = ONE_TIME_DEVICE_ONLY_CODE;
        }

        if(selectedPackageCode == DEVICE_WITH_PANEL_CODE){
          code = DEVICE_WITH_PANEL_OTP_CODE;
        }          

        const response = await postData('package/info', {PackageCode: code});
        if (response?.data) {
          let non_distributor_amount = parseFloat(response.data.PackageAmount);
          let non_distributor_discount = parseFloat(response.data.PackageDiscountAmount);
          let non_distributor_discounted_amount = non_distributor_amount - non_distributor_discount;
          setSavingsToday(non_distributor_discount);

          setTotalCost(non_distributor_discounted_amount);
          setNonDistributorPackage(response.data);
        }
      } catch (error) {
        console.error("error on submit:", error);
      }       
    }

  };

  const handleQuantityChange = (event) => {
    const newValue = event.target.value === '' ? '' : Math.max(1, parseInt(event.target.value, 10));
    setQuantity(newValue);
  };
  
  useEffect(() => {
    if(isDistributor){
      let distributor_amount = parseFloat(distributorPackage.PackageAmount);
      // let distributor_discount = parseFloat(distributorPackage.PackageDiscountDistributor) / 100;
      // let distributor_discounted_amount = distributor_amount - (distributor_amount * distributor_discount);      
      let distributor_discount = parseFloat(distributorPackage.PackageDiscountAmount);

      let distributor_discounted_amount = distributor_amount - distributor_discount;
      setSavingsToday(parseInt(distributorPackage.PackageDiscountAmount) * quantity);
      setTotalCost(distributor_discounted_amount * quantity);
    }else{
      let non_distributor_amount = parseFloat(nonDistributorPackage.PackageAmount);
      let non_distributor_discount = parseFloat(nonDistributorPackage.PackageDiscountAmount);
      let non_distributor_discounted_amount = non_distributor_amount - non_distributor_discount;
      setSavingsToday(non_distributor_discount);
      setTotalCost(non_distributor_discounted_amount * quantity);
    }

    if(quantity == '' || quantity == 0){
      setSavingsToday('0.00');
    }

  }, [isDistributor, quantity]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  // Image array (replace with your image paths)
  const images = [
    product1,
    product2,
    product3
  ];

  const fetchPackages = async () => {
    setIsLoading(true); // Show a loading indicator
    try {
      const response = await postData('packages', {'endpoint_type': 'public'}); 
      
      if (response.code !== 200) {
        throw new Error('Failed to fetch packages');
      }
     
      setPackages(response.data.packages); 
      setMinimumDistributorOrder(response.data.minimum_distributor_order); 
    } catch (error) {
      toast.error('Error fetching packages: ' + error.message);
    } finally {
      setIsLoading(false); // Hide the loading indicator
    }
  };
  
  const handleOptionChange = (e) => {
    const selectedCode = e.target.dataset.code;
    const packageId = e.target.id;
    setSelectedOption(selectedCode);
    setSelectedPackageID(packageId);
    setSelectedPackageCode(selectedCode);

    // Find the selected package based on PackageID
    const selectedPackage = packages.find(packageItem => packageItem.PackageCode === selectedCode);

    if (selectedPackage) {
      setPriceDetails({
        amount: `₱ ${selectedPackage.PackageAmount}`,
        deposit: `₱ ${selectedPackage.PackageDeposit}`, 
        term: `${selectedPackage.PackageTerm}` 
      });
    } else {
      // Fallback if no package is found (Optional)
      setPriceDetails({ amount: '₱ 0.00', deposit: '₱ 0.00' });
    }
  };
  
  const handlePayment= async () => {

    const url = new URL(window.location.href); 
    const searchParams = new URLSearchParams(url.search);
    const code = searchParams.get('code');

    if (quantity == '' || quantity == 0) {
      showWarning('Quantity is required.');
      return;       
    }

    if (!fullname || !email) {
      showWarning('Please fill out the entire form.');
      return; 
    }

    if(!code){
      showWarning('Invalid Referral Code');
      return;
    }

    if(!deliveryAddress && isOneTimePayment){
      showWarning('Delivery Address is required.');
      return;
    }
    
    setIsLoading(true);
    
    const data = {
      'code': code,
      'package_id': selectedPackageID,
      'package_code': selectedOption,
      'order_type': orderType,
      'quantity': quantity,
      'fullname': fullname,
      'email': email,
      'delivery_address': deliveryAddress,
      'is_one_time_payment': isOneTimePayment,
      'endpoint_type': 'public'
    };

    try {
      const response = await postData('payment/signup', data);
      if(response?.data){
        const checkoutUrl = response.data.attributes.checkout_url;
        
        data['paymentId'] = response.data.id; 

        saveToLocalStorage("paymentDetails", data);
  
        // Redirect to the checkout URL
        window.location.href = checkoutUrl;
      }
      


    } catch (error) {
      console.error("error on submit:", error);
    } finally {
      setIsLoading(false); 
    }
  }

  const handlePreOrderClick = () => {
    setShowOrderContent(true); // Show the order form
  };

  const handleBack = () => {
    setShowOrderContent(false); // Hide order content on back
    setIsOneTimePayment(false);
    setOrderType(LAY_AWAY_CODE);  
    setQuantity(1);  
    
  };

  useEffect(() => {
    fetchPackages();
  }, []);
  
  useEffect(() => {
    const defaultPackage = packages.find(p => p.PackageCode === DEVICE_ONLY_CODE);

    if (defaultPackage) {
      setSelectedPackageID(defaultPackage.PackageID);          
      setSelectedPackageCode(defaultPackage.PackageCode);          
      setSelectedPackageTerm(defaultPackage.PackageTerm);  

      setPriceDetails({
        amount: `₱ ${defaultPackage.PackageAmount}`,
        deposit: `₱ ${defaultPackage.PackageDeposit}`,
        term: `${defaultPackage.PackageTerm}`
      });
    }
  }, [packages]);

  useEffect(() => {
    const fetchPackageInfo = async () => {
      if (quantity >= minimumDistributorOrder && !isDistributor && minimumDistributorOrder !== 0) {
        try {
          let code = '';
          if(selectedPackageCode == DEVICE_ONLY_CODE){
            code = DISTRIBUTOR_DEVICE_ONLY_CODE;
          }

          if(selectedPackageCode == DEVICE_WITH_PANEL_CODE){
            code = DISTRIBUTOR_DEVICE_WITH_PANEL_CODE;
          }          

          const response = await postData('package/info', {PackageCode: code});
          if (response?.data) {
            setDistributorPackage(response.data)
          }
        } catch (error) {
          console.error("error on submit:", error);
        }      
  
        setIsDistributor(true);
      } else if (quantity < minimumDistributorOrder) {
        setIsDistributor(false);
        setDistributorPackage({})
      }
    };
  
    fetchPackageInfo();
  }, [quantity, minimumDistributorOrder, isDistributor]);
  

  return (
    <div className={`${classes.header} ${isFloating ? classes.floating : ''}`}>
      <img src={Logo} alt='Logo' className={classes.logo} />
      <nav className={classes.nav}>
        <ul className={classes.navList}>
          <li className={classes.navItem} onClick={() => scrollToSection('product-highlight')}>Product</li>
          <li className={classes.navItem} onClick={() => scrollToSection('benefits-section')}>Benefits</li>
          <li className={classes.navItem} onClick={() => scrollToSection('get-it-free')}>Get It Free</li>
          <li className={classes.navItem}>Contact</li>
          <li className={classes.navItem}>
            <button className={classes.orderButton} onClick={handleToggleModal}>Order Now!</button>
          </li>
        </ul>
      </nav>

      {/* Hidden images for preloading */}
      <div style={{ display: 'none' }}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Preload ${index}`} />
        ))}
      </div>

      {isModalOpen && (
        <div className={classes.modalOverlay}>
          <div className={`${classes.modal} ${showOrderContent ? classes.modalNarrow : ''}`}>
            <button className={classes.modalCloseButton} onClick={toggleModal}>×</button>
            {!showOrderContent && (
              <div className={classes.modalContent}>
                <div className={classes['item-slider']} >
                  <Slider {...sliderSettings}>
                      {images.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Slide ${index}`} />
                        </div>
                      ))}
                    </Slider>
                </div>

                <div className={classes['item-details']}>
                  <div className={classes['category']}>PORTABLE POWER STATION</div>
                  <div className={classes['name']}>G5 SolarTech</div>
                  <div className={classes['model']}>MODEL {packages && packages.length > 0 && packages[0].PackageName}</div>
                  <div className={classes['price']}>
                    <span className={classes['amount']}>{priceDetails && (formatCurrency(priceDetails.amount))}</span>
                    <span className={classes['badge']}>Deposit only @ {priceDetails && (formatCurrency(priceDetails.deposit))}</span>
                  </div>
                  
                  <div className={classes['terms']}>
                    <span className={classes['terms-info']}>Payable in {selectedPackageTerm} months</span>
                  </div>

                  <div className={classes['radio-options']}>
                  {packages.map((packageItem) => (
                    <div key={packageItem.PackageID} className={classes['radio-input']}>
                      <input
                        type="radio"
                        id={`${packageItem.PackageID}`} 
                        name="productOption"
                        value={packageItem.PackageAmount}
                        
                        defaultChecked={selectedOption === packageItem.PackageCode}
                        onChange={handleOptionChange}
                        data-code={packageItem.PackageCode}
                      />
                      <label htmlFor={`${packageItem.PackageID}`} className={classes['radio-label']}>{packageItem.PackageDesc}</label>
                    </div>
                  ))}
                  </div>
                  <div className={classes['free-shipping-banner']}>FREE SHIPPING</div>
                  <div className={classes['desc']}>
                    <p>
                      The Portable Solar Power Station offers a sustainable and efficient energy 
                      solution for outdoor enthusiasts and emergency situations alike. Compact and eco-friendly, 
                      it harnesses solar energy to provide reliable power for devices and appliances. 
                      Equipped with multiple charging ports and a high-capacity battery, <b>it's ideal for camping, 
                      road trips, or as a backup power source</b>. Its durable design ensures performance in various conditions, 
                      making it a versatile, green power supply for all your adventures.
                    </p>

                  </div> 
                  <button className={classes['pre-order']} onClick={handlePreOrderClick} >PRE-ORDER NOW</button>

                  
                </div>

                

              </div>
            )}              

            {showOrderContent && ( 
              <div className={classes.orderContent}>
                <div className={classes["two-column-flex"]}>
                  <fieldset className={classes["order-type-section"]}>
                    <legend className={classes["legend-title"]}>Type of Order</legend>
                      <div className={classes["radio-option"]}>
                        <input
                          type="radio"
                          id="layaway"
                          name="orderType"
                          value={LAY_AWAY_CODE}
                          onChange={handleOrderTypeChange}
                          defaultChecked
                        />
                        <label htmlFor="layaway">Lay away</label>
                      </div>
                      <div className={classes["radio-option"]}>
                        <input
                          type="radio"
                          id="oneTimePayment"
                          name="orderType"
                          value={ONE_TIME_PAYMENT_CODE}
                          onChange={handleOrderTypeChange}
                        />
                        <label htmlFor="oneTimePayment">One Time Payment</label>
                      </div>


                      {orderType === LAY_AWAY_CODE && (
                        <div className={classes["deposit-amount-section"]}>
                          <h3>Initial Deposit Required</h3>
                          <p className={classes["deposit-amount"]}>
                            <strong>{formatCurrency(priceDetails?.deposit || 0, 'PHP')}</strong>
                          </p>
                          <p className={classes.note}>Note: This deposit secures your order and is part of the layaway plan.</p>
                        </div>
                      )}

                      {isOneTimePayment && (
                        <div>
                          <div className={classes["total-amount-section"]}>
                            <h3>Your Total Cost</h3>
                            <div className={classes["amount-and-savings"]}>
                              <strong>{formatCurrency(totalCost || 0, 'PHP')}</strong>
                              {isDistributor ? (
                                <span className={classes["savings"]}>Save {formatCurrency(savingsToday, 'PHP')} today!</span>
                              ) : (
                                <span className={classes["savings"]}>Save {formatCurrency(savingsToday, 'PHP')} per product today!</span>
                              )}
                            </div>                            
                          </div>

                          <div className={classes["quantity-input"]}>
                            <label htmlFor="quantity">Qty:</label>
                            <input
                              type="number"
                              id="quantity"
                              name="quantity"
                              value={quantity} 
                              onChange={handleQuantityChange} 
                            />
                          </div>

                          <div className={classes.note}>
                            Note: The minimum quantity for distributors is 6 units.
                          </div>
                        </div>
                      )}        
                  </fieldset>
                  <div className={classes["column-content"]}>
                    <label htmlFor="fullname" className={classes.visuallyHidden}>Full Name</label>
                    <input
                      type="text"
                      id="fullname"
                      name="fullname"
                      placeholder="Full Name"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      required
                    />
                    <label htmlFor="email" className={classes.visuallyHidden}>Email Address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {isOneTimePayment && (
                      <>
                        <label htmlFor="deliveryAddress" className={classes.visuallyHidden}>Delivery Address</label>
                        <textarea
                          id="deliveryAddress"
                          className={classes.deliveryAddressTextarea}
                          placeholder="Enter delivery address"
                          value={deliveryAddress}
                          onChange={(e) => setDeliveryAddress(e.target.value)}
                          required
                        ></textarea>
                      </>
                    )}
                  </div>

                </div>
                <button onClick={handleBack}>Back</button>
                <button className={classes['pre-order']} onClick={handlePayment} disabled={isLoading}>
                  <div className={classes.buttonContent}>
                    {isLoading ? 'Processing...' : 'Proceed'}
                    {isLoading && <div className={classes.spinner}></div>}
                  </div>
                </button>
              </div>
            )}
            </div>
          
        </div>
      )}

      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default PublicHeader;
